import { Box, CircularProgress } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import GenericStartPrequalificationDialogForm from '@/features/activationGroup/components/GenericStartPrequalificationDialogForm'
import { useActivationGroupQuery } from '@/features/activationGroup/hooks/useActivationGroupQuery'
import { useGenericActivationTriggerChannelsQuery } from '@/features/activationGroup/hooks/useGenericActivationTriggerChannelsQuery'
import { useInvalidateGenericPrequalificationsQuery } from '@/features/activationGroup/hooks/useGenericPrequalificationsQuery'
import { useGenericStartPrequalificationMutation } from '@/features/activationGroup/hooks/useGenericStartPrequalificationMutation'
import type { GenericStartPrequalification } from '@/features/activationGroup/types'
import { errorHandler } from '@/utils/errorHandler'

type GenericStartPrequalificationDialogProps = {
  patternUuid: string
  open: boolean
  onClose: () => void
}

const GenericStartPrequalificationDialog = ({
  patternUuid,
  open,
  onClose,
}: GenericStartPrequalificationDialogProps) => {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()
  const { activationGroupUuid = null } = useParams()

  const { startPrequalification, isPending } = useGenericStartPrequalificationMutation()
  const invalidatePrequalificationsQuery = useInvalidateGenericPrequalificationsQuery()

  const { activationGroup, isLoading: isActivationGroupLoading } = useActivationGroupQuery(
    { activationGroupUuid },
    { enabled: !!activationGroupUuid && open },
  )
  const { activationTriggerChannels, isLoading: isChannelsLoading } = useGenericActivationTriggerChannelsQuery(
    { activationGroupUuid: activationGroupUuid || '' },
    { enabled: !!activationGroupUuid && open },
  )

  const isLoading = isPending || isActivationGroupLoading || isChannelsLoading

  // Memoize transformed data only if activationTriggerChannels actually change
  const transformedChannels = useMemo(() => {
    if (!activationTriggerChannels?.length || !activationGroup) return []

    return activationTriggerChannels.map((channel: number) => ({
      value: channel,
      label: `${activationGroup.portfolio.code} @ ${channel}`,
      id: `Channel-${channel}`,
    }))
  }, [activationTriggerChannels, activationGroup])

  function handleClose() {
    onClose()
  }

  async function handleSubmit(data: GenericStartPrequalification) {
    try {
      await startPrequalification(data)
      await invalidatePrequalificationsQuery()

      pushAlert({
        message: t('activation_groups.start_prequalification_success_message'),
        severity: 'success',
      })
      onClose()
    } catch (err) {
      const error = errorHandler(err, t('activation_groups.start_prequalification_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomDialog open={open} title={t('activation_groups.start_prequalification_dialog.title')} onClose={handleClose}>
      {isLoading ? (
        <Box alignItems="center" display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : (
        <GenericStartPrequalificationDialogForm
          activationTriggerChannels={transformedChannels}
          isLoading={isPending}
          patternUuid={patternUuid}
          onCancel={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </CustomDialog>
  )
}

export default GenericStartPrequalificationDialog
