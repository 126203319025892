import { zodResolver } from '@hookform/resolvers/zod'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  Box,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import type { ReactNode } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomButton from '@/components/inputs/CustomButton'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import { useAlertContext } from '@/contexts/AlertContext'
import ResourceCard from '@/features/resource/components/ResourceCard'
import { useResourceData } from '@/features/resource/contexts/ResourceDataContext'
import { GET_RESOURCE_API_ID } from '@/features/resource/endpoints/resources'
import { useUpdateMeteringPortRelationMutation } from '@/features/resource/hooks/useUpdateMeteringPortRelationMutation'
import { getMeteringRelationSchema } from '@/features/resource/schemas'
import type {
  ControlPort,
  CustomerResourceStatusInfo,
  MeteringPort,
  MeteringRelationSchemaType,
} from '@/features/resource/types'
import { getControlBoxTypeLabel } from '@/features/resource/utils/controlBoxTypes'
import { getControlTypeLabel } from '@/features/resource/utils/controlType'
import { errorHandler } from '@/utils/errorHandler'

const StyledMultiLineErrorsCell = styled(TableCell)(() => ({
  '&.MuiTableCell-root': {
    whiteSpace: 'pre-wrap',
  },
}))

const StyledNoBorderTableRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const ResourcePortsTable = ({
  rows,
  columns,
  ariaLabel,
  title,
}: {
  rows: ReactNode
  columns: string[]
  ariaLabel: string
  title: string
}) => {
  return (
    <>
      <CardHeader sx={{ paddingX: 0 }} title={title}></CardHeader>
      <Table aria-label={ariaLabel} size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </>
  )
}

const ArithmeticOperationCell = ({ meteringPort, resourceId }: { meteringPort: MeteringPort; resourceId: string }) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(false)
  const [currentArithmeticOperation, setCurrentArithmeticOperation] = useState(
    meteringPort.arithmeticOperation || 'PLUS',
  )
  const { pushAlert } = useAlertContext()
  const queryClient = useQueryClient()
  const { updateMeteringPortRelation: updateRelationType, isPending: isArithmeticOperationeUpdatePending } =
    useUpdateMeteringPortRelationMutation()

  useEffect(() => {
    setCurrentArithmeticOperation(meteringPort.arithmeticOperation || 'PLUS')
  }, [meteringPort.arithmeticOperation])

  const form = useForm<MeteringRelationSchemaType>({
    defaultValues: {
      arithmeticOperation: currentArithmeticOperation,
    },
    resolver: zodResolver(getMeteringRelationSchema(t)),
  })

  const onSubmit = async (data: MeteringRelationSchemaType) => {
    try {
      setCurrentArithmeticOperation(data.arithmeticOperation)
      setIsEditing(false)
      await updateRelationType({
        resourceId,
        meteringPortId: meteringPort.powerMeterID,
        arithmeticOperation: data.arithmeticOperation,
      })

      queryClient.invalidateQueries({ queryKey: [GET_RESOURCE_API_ID, { id: resourceId }] })
      pushAlert({
        message: t('resources.metering_port.relation.success_update'),
        severity: 'success',
      })
    } catch (err) {
      const error = errorHandler(err, t('resources.metering_port.relation.error_update'))
      setCurrentArithmeticOperation(meteringPort.arithmeticOperation || 'PLUS')
      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  if (isEditing) {
    return (
      <Box width="100%">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing={1}>
              <SelectFieldController
                fullWidth
                id={`arithmeticOperation-${meteringPort.powerMeterID}`}
                name="arithmeticOperation"
                options={[
                  { value: 'PLUS', label: t('resources.metering_port.relation.additive'), id: 'PLUS' },
                  { value: 'MINUS', label: t('resources.metering_port.relation.subtractive'), id: 'MINUS' },
                ]}
                size="small"
              />
              <Stack direction="row" justifyContent="flex-start" spacing={1}>
                <CustomButton
                  disabled={isArithmeticOperationeUpdatePending}
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {t('common.button.save')}
                </CustomButton>
                <CustomButton
                  disabled={isArithmeticOperationeUpdatePending}
                  size="small"
                  variant="outlined"
                  onClick={() => setIsEditing(false)}
                >
                  {t('common.button.cancel')}
                </CustomButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    )
  }

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
      <Stack alignItems="center" direction="row" spacing={0.5}>
        {currentArithmeticOperation === 'MINUS' ? (
          <>
            <RemoveIcon color="error" fontSize="medium" />
            {t('resources.metering_port.relation.subtractive')}
          </>
        ) : (
          <>
            <AddIcon color="success" fontSize="medium" />
            {t('resources.metering_port.relation.additive')}
          </>
        )}
      </Stack>
      <IconButton
        aria-label={t('resources.metering_port.relation.edit')}
        size="small"
        onClick={() => setIsEditing(true)}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </Stack>
  )
}

const ResourceControlPortsTable = ({ controlPorts }: { controlPorts: ControlPort[] }) => {
  const { t } = useTranslation()
  const columns = useMemo(() => {
    return [
      t('resources.detail_panel.controllers_header'),
      t('resources.detail_panel.ports_header'),
      t('resources.detail_panel.type_header'),
      t('resources.detail_panel.power_meters_header'),
      t('resources.detail_panel.errors_header'),
    ]
  }, [t])

  return (
    <ResourcePortsTable
      ariaLabel={t('resources.detail_panel.control_ports_table_label')}
      columns={columns}
      rows={
        <>
          {controlPorts.map((controlPort) => (
            <TableRow key={controlPort.controlPortID}>
              <TableCell>
                {controlPort.controlPortID} ({getControlBoxTypeLabel(controlPort.controlBoxType, t)})
              </TableCell>
              <TableCell>{controlPort.ports}</TableCell>
              <TableCell>{getControlTypeLabel(controlPort.category, t)}</TableCell>
              <TableCell>{controlPort.powerMeterID}</TableCell>
              <StyledMultiLineErrorsCell>{controlPort.errors ?? '-'}</StyledMultiLineErrorsCell>
            </TableRow>
          ))}
        </>
      }
      title={t('resources.detail_panel.control_ports_header')}
    />
  )
}

const ResourceMeteringPortsTable = ({ meteringPorts }: { meteringPorts: MeteringPort[] }) => {
  const { t } = useTranslation()
  const { resource } = useResourceData()

  const columns = useMemo(() => {
    return [
      t('resources.detail_panel.controllers_header'),
      t('resources.detail_panel.measuring_header'),
      t('resources.detail_panel.relation_type_header'),
      t('resources.detail_panel.errors_header'),
    ]
  }, [t])

  if (!resource?.resourceID) {
    return null
  }

  return (
    <ResourcePortsTable
      ariaLabel={t('resources.detail_panel.metering_ports_table_label')}
      columns={columns}
      rows={
        <>
          {meteringPorts.map((meteringPort) => (
            <TableRow key={meteringPort.powerMeterID}>
              <TableCell>
                {meteringPort.powerMeterID} {meteringPort.controllerName && `(${meteringPort.controllerName})`}
              </TableCell>
              <TableCell>
                {meteringPort.isSingleResource
                  ? t('resources.detail_panel.single_resource')
                  : t('resources.detail_panel.multiple_resources')}
              </TableCell>
              <TableCell>
                <ArithmeticOperationCell meteringPort={meteringPort} resourceId={resource.resourceID} />
              </TableCell>
              <StyledMultiLineErrorsCell>{meteringPort.errors ?? '-'}</StyledMultiLineErrorsCell>
            </TableRow>
          ))}
        </>
      }
      title={t('resources.detail_panel.metering_ports_header')}
    />
  )
}

const CustomerResourceStatusInfo = ({
  customerResourceStatusInfo,
}: {
  customerResourceStatusInfo: CustomerResourceStatusInfo
}) => {
  const { t } = useTranslation()

  const columns = [
    {
      label: t('resources.customer_resource_status.status'),
      value: customerResourceStatusInfo?.status ?? '-',
    },
    {
      label: t('resources.customer_resource_status.reason'),
      value: customerResourceStatusInfo?.reason ?? '-',
    },
    {
      label: t('resources.customer_resource_status.description'),
      value: customerResourceStatusInfo?.description ?? '-',
    },
    {
      label: t('resources.customer_resource_status.updated_at'),
      value: customerResourceStatusInfo.updatedAt
        ? DateTime.fromISO(customerResourceStatusInfo.updatedAt).toFormat('dd MMMM, HH:mm')
        : '-',
    },
  ]

  if (
    !customerResourceStatusInfo.status &&
    !customerResourceStatusInfo.reason &&
    !customerResourceStatusInfo.description &&
    !customerResourceStatusInfo.updatedAt
  ) {
    return null
  }

  return (
    <>
      <CardHeader sx={{ paddingX: 0 }} title={t('resources.customer_resource_status.label')}></CardHeader>
      <Box display="flex">
        <Table aria-label={t('resources.customer_resource_status.label')} size="small">
          <TableHead>
            <StyledNoBorderTableRow>
              {columns.map((column) => (
                <TableCell key={column.label}>
                  <CustomTypography color="grey.500" variant="caption">
                    {column.label}
                  </CustomTypography>
                </TableCell>
              ))}
            </StyledNoBorderTableRow>
          </TableHead>
          <TableBody>
            <StyledNoBorderTableRow>
              {columns.map((row) => (
                <TableCell key={row.value}>
                  <CustomTypography variant="inherit">{row.value}</CustomTypography>
                </TableCell>
              ))}
            </StyledNoBorderTableRow>
          </TableBody>
        </Table>
      </Box>
      <Divider />
    </>
  )
}

const ResourceGeneralCard = () => {
  const { resource } = useResourceData()
  if (resource === undefined || resource === null) {
    return null
  }

  const containsControlPorts = resource.controlPorts.length > 0
  const containsMeteringPorts = resource.meteringPorts.length > 0

  return (
    <ResourceCard
      content={
        (containsControlPorts || containsMeteringPorts || resource.customerResourceStatusInfo) && (
          <>
            {resource.customerResourceStatusInfo && (
              <CustomerResourceStatusInfo customerResourceStatusInfo={resource.customerResourceStatusInfo} />
            )}
            {containsControlPorts && <ResourceControlPortsTable controlPorts={resource.controlPorts} />}
            {containsMeteringPorts && <ResourceMeteringPortsTable meteringPorts={resource.meteringPorts} />}
          </>
        )
      }
    />
  )
}

export default ResourceGeneralCard
