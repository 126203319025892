import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppBarContent } from '@/components/layouts/Page'
import { RevenueContent } from '@/features/site/components/revenue/RevenueContent'
import { RevenueMarketProgramTabs } from '@/features/site/components/revenue/RevenueMarketProgramTabs'
import { useRevenuePeriodQuery } from '@/features/site/hooks/useRevenuePeriodQuery'
import { useRevenueTimeSeriesQuery } from '@/features/site/hooks/useRevenueTimeSeriesQuery'
import SiteRevenueAppBar from '@/features/site/pages/components/SiteRevenueAppBar'
import type { MarketProgram } from '@/features/site/types/marketProgram'
import type { RevenueDataRow } from '@/features/site/types/revenue'
import type { Site } from '@/features/site/types/site'
import {
  getAvailableYearsForMarketProgram,
  getRevenueTimeSeriesEndTime,
  getRevenueTimeSeriesStartTime,
} from '@/features/site/utils/revenueUtils/dateUtils'
import { formatRevenueTimeSeries } from '@/features/site/utils/revenueUtils/formatRevenueTimeSeries'
import { getMarketProgramsOptions } from '@/features/site/utils/revenueUtils/marketProgramOptions'
import type { MarketProgramType } from '@/types/marketProgramType'

type SiteRevenueViewOldProps = {
  site: Site
}

export function SiteRevenueViewOld({ site }: Readonly<SiteRevenueViewOldProps>) {
  const { t } = useTranslation()
  const { setAppBarContent } = useAppBarContent()

  const isReadyToFetchRevenueData = Boolean(site.uuid) && site.location === 'fi'

  const timezone = site.timezone ?? DateTime.utc().zoneName

  const { revenuePeriods, isFetching: isFetchingRevenuePeriod } = useRevenuePeriodQuery(
    { customerUuid: site.uuid },
    { enabled: isReadyToFetchRevenueData },
  )

  const relevantMarketPrograms = getMarketProgramsOptions(
    site.marketProgramContracts
      ?.map((contract) => contract.marketProgram)
      .filter((marketProgram) => marketProgram !== undefined) ?? [],
    revenuePeriods ?? [],
  )
  const [marketProgramSelection, setMarketProgramSelection] = useState<MarketProgram>()

  const [yearOptions, setYearOptions] = useState<string[]>([])
  const [yearSelection, setYearSelection] = useState<string>('')

  const handleMarketProgramChange = (newValue: MarketProgramType) => {
    const newMarketProgram = relevantMarketPrograms.find((program) => program.type === newValue)!
    setMarketProgramSelection(newMarketProgram)
    const availableYears = getAvailableYearsForMarketProgram(revenuePeriods ?? [], timezone, newMarketProgram)
    setYearOptions(availableYears)
    setYearSelection(availableYears[0])
  }

  const revenueTimeSeriesStartTime = getRevenueTimeSeriesStartTime(
    revenuePeriods,
    timezone,
    marketProgramSelection,
    yearSelection,
  )

  const revenueTimeSeriesEndTime = getRevenueTimeSeriesEndTime(
    revenuePeriods,
    timezone,
    marketProgramSelection,
    yearSelection,
  )

  useEffect(() => {
    if (!marketProgramSelection) {
      setMarketProgramSelection(relevantMarketPrograms.length > 0 ? relevantMarketPrograms[0] : undefined)
    }
  }, [revenuePeriods])

  useEffect(() => {
    const availableYears = getAvailableYearsForMarketProgram(revenuePeriods ?? [], timezone, marketProgramSelection)
    setYearOptions(availableYears)
    if (availableYears.length > 0) {
      setYearSelection(
        yearSelection === '' || !availableYears.includes(yearSelection) ? availableYears[0] : yearSelection,
      )
    }
  }, [revenuePeriods, marketProgramSelection])

  const { revenueTimeSeries, isFetching: isLoadingRevenueTimeSeries } = useRevenueTimeSeriesQuery(
    {
      customerUuid: site.uuid!,
      serviceId: marketProgramSelection?.id ?? 0,
      startTime: revenueTimeSeriesStartTime ?? DateTime.local({ zone: timezone }),
      endTime: revenueTimeSeriesEndTime ?? DateTime.local({ zone: timezone }),
    },
    {
      enabled: !isFetchingRevenuePeriod && Boolean(revenueTimeSeriesStartTime) && Boolean(revenueTimeSeriesEndTime),
    },
  )

  const revenueData: RevenueDataRow[] = useMemo(() => {
    return formatRevenueTimeSeries(revenueTimeSeries, timezone)
  }, [revenueTimeSeries, timezone])

  useEffect(() => {
    setAppBarContent(
      <SiteRevenueAppBar
        availableYears={yearOptions}
        customerName={site.name ?? ''}
        revenueData={revenueData}
        selectedMarketProgramType={marketProgramSelection?.type ?? ''}
        setYearSelection={setYearSelection}
        yearSelection={yearSelection}
      />,
    )
  }, [yearOptions, revenueData, site.name, marketProgramSelection?.type, yearSelection, setAppBarContent])

  if (!isFetchingRevenuePeriod && (!revenuePeriods || revenuePeriods.length === 0))
    return (
      <Typography align={'center'} sx={{ m: 4 }}>
        {t('customer_revenue.no_revenue_data')}
      </Typography>
    )

  return (
    <Stack direction="column" mb={2} mt={2} spacing={3} sx={{ width: '100%' }}>
      <RevenueMarketProgramTabs
        handleMarketProgramChange={handleMarketProgramChange}
        marketProgramSelection={marketProgramSelection}
        marketProgramsOptions={relevantMarketPrograms}
      />
      <RevenueContent
        isLoadingRevenueTimeSeries={isLoadingRevenueTimeSeries}
        revenueData={revenueData}
        selectedYear={yearSelection}
      />
    </Stack>
  )
}
