import { Stack } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import { ExportCsvButton } from '@/features/site/components/revenue/ExportCsvButton'
import { SiteSelector } from '@/features/site/components/SiteSelector'
import type { RevenueDataRow } from '@/features/site/types/revenue'

type SiteRevenueAppBarProps = {
  availableYears: string[]
  yearSelection: string
  setYearSelection: Dispatch<SetStateAction<string>>
  selectedMarketProgramType: string
  revenueData: RevenueDataRow[]
  customerName: string
}

function SiteRevenueAppBar({
  availableYears,
  yearSelection,
  setYearSelection,
  selectedMarketProgramType,
  revenueData,
  customerName,
}: Readonly<SiteRevenueAppBarProps>) {
  const { t } = useTranslation()
  const { siteUuid } = useParams()
  const { permissions } = usePermissions()

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={1} sx={{ width: '100%' }}>
      <Stack direction="row" flex={1} spacing={1}>
        {permissions.has('siteDetails') && <SiteSelector siteUuid={siteUuid} />}

        {availableYears.length > 0 && (
          <CustomSelectField
            label={t('common.time.year')}
            options={availableYears.map((year, index) => ({
              id: index.toString(),
              label: year.toString(),
              value: year.toString(),
            }))}
            size="medium"
            value={yearSelection}
            variant="outlinedWhite"
            onChange={(event) => {
              setYearSelection(event.target.value)
            }}
          />
        )}
      </Stack>

      <ExportCsvButton
        customerName={customerName}
        data={revenueData}
        marketProgramType={selectedMarketProgramType}
        year={yearSelection}
      />
    </Stack>
  )
}

export default SiteRevenueAppBar
