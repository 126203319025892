import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import {
  GET_ACTIVATION_GROUP_SCHEDULES_API,
  getGenericActivationTriggerChannels,
} from '@/features/activationGroup/endpoints/activationGroups'
import type { GenericActivationTriggerChannels } from '@/features/activationGroup/types'
import type { UseQueryOptions } from '@/types/queries'

type UseGenericActivationTriggerChannelsQueryParams = {
  activationGroupUuid: string
}

export type UseGenericActivationTriggerChannelsQueryResult = Omit<UseQueryResult, 'data'> & {
  activationTriggerChannels: number[] | null
}

export function useGenericActivationTriggerChannelsQuery(
  params: UseGenericActivationTriggerChannelsQueryParams,
  options?: UseQueryOptions<GenericActivationTriggerChannels>,
): UseGenericActivationTriggerChannelsQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_ACTIVATION_GROUP_SCHEDULES_API, params],
    queryFn: () => getGenericActivationTriggerChannels(params.activationGroupUuid),
  })

  return {
    ...queryResult,
    activationTriggerChannels: data?.activationTriggerChannels ?? null,
  }
}
